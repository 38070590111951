import { Container, H2, H3, Paragraph, YStack } from '@holo/ui';
import Image from 'next/image';
import cropPlanningMapMobile from 'public/img/crop-planning-map-mobile.png';
import cropPlanningMap from 'public/img/crop-planning-map.png';

const Section2 = () => {
  return (
    <YStack backgroundColor="$contrast" id="crop-planner">
      <Container>
        <YStack gap="$8" py="$14" $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }}>
          {/* Top (left on desktop) */}
          <YStack $sm={{ flex: 1, flexBasis: 0 }}>
            <H2 color="$base" fontSize="$32" $md={{ fontSize: '$40' }}>
              AI-powered crop planning
            </H2>

            <Paragraph lineHeight="$2" color="$basePale" mt="$4">
              We are developing the worlds first AI-powered crop planner that will revolutionize your ability to
              optimize your crop plan and maximize your yields. Once configured, all you have to do is to is to press a
              button to automatically generate a crop plan that is optimized for your harvest forecast, greenhouses,
              fields, high-tunnels, crop specifications, weather data, historic planting, inputs, and soil tests.
            </Paragraph>

            <YStack $md={{ flexDirection: 'row', gap: '$8' }}>
              <YStack $md={{ flex: 1, flexBasis: 0 }}>
                <H3 mt="$8" $sm={{ fontSize: '$16' }} color="$base" fontSize="$14" fontWeight="$medium" lineHeight="$2">
                  Crop plans that are executable
                </H3>
                <Paragraph lineHeight="$2" color="$basePale" mt="$4">
                  Gone are the days when crop plans were just static plans. With holo you can make your crop plans
                  executable by configuring all the tasks you need from seeding, watering, to plant maintenance, and
                  transplanting. Once set up, the task can be either automatically scheduled or trigged by certain
                  events.
                </Paragraph>
              </YStack>

              <YStack $md={{ flex: 1, flexBasis: 0 }}>
                <H3 mt="$8" color="$base" fontSize="$14" $sm={{ fontSize: '$16' }} fontWeight="$medium" lineHeight="$2">
                  Give your customers real time access to what is available in your fields
                </H3>
                <Paragraph lineHeight="$2" color="$basePale" mt="$4">
                  Since your crop plans are executable and are updated daily, they become your real time inventory of
                  what is available in your field. You field workers can easily track and update expected yields and
                  harvest times directly out in the field. Since this is directly reflected in what is available in your
                  online store, you will boost your customer trust and satisfaction with real time information on what
                  is actually growing in your fields and available for sale.
                </Paragraph>
              </YStack>
            </YStack>
          </YStack>

          {/* Bottom (right on desktop) */}
          <YStack position="relative" justifyContent="center" $sm={{ flex: 1, flexBasis: 0 }}>
            <Image
              src={cropPlanningMap}
              alt="Crop planning map"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Image
              src={cropPlanningMapMobile}
              alt="Crop planning map mobile"
              style={{
                width: '25vw',
                minWidth: 100,
                maxWidth: 178,
                height: 'auto',
                margin: '-30% auto 0 auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section2;
